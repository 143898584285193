import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';

type SnackbarCloseButtonProps = {
  id?: SnackbarKey
};

function SnackbarCloseButton({ id }: SnackbarCloseButtonProps) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarCloseButton;
