import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from './appSlice';

type Props = {
  onlyButton?: boolean
};

const UpdateApp = ({ onlyButton = false }: Props) => {
  const canUpdate = useSelector(appSelectors.selectUpdate);
  const serviceWorkerRegistration = useSelector(appSelectors.selectWorker);
  if (canUpdate && serviceWorkerRegistration) {
    const updateServiceWorker = () => {
      const registrationWaiting = serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e) => {
          if (e.target && (e.target as any).state === 'activated') {
            window.location.reload();
          }
        });
      }
    };
    if (onlyButton) {
      return (<Button variant="outlined" fullWidth size="small" onClick={updateServiceWorker}>Update</Button>);
    }
    return (
      <Alert severity="info">
        You can update Stingray Navigator Mobile
        <Button variant="outlined" size="small" onClick={updateServiceWorker}>Update</Button>
      </Alert>
    );
  }
  return (null);
};

export default UpdateApp;
