/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type NavLayoutTypes = 'buttons' | 'camera' | 'advanced';

type AppState = {
  canUpdate: boolean,
  serviceworker: null | ServiceWorkerRegistration,
  navLayout: NavLayoutTypes,
};

const initialState: AppState = {
  canUpdate: false,
  serviceworker: null,
  navLayout: localStorage.getItem('navLayout') as NavLayoutTypes ?? 'buttons',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    canUpdate: (state, action: PayloadAction<{ updateable: boolean, serviceworker: ServiceWorkerRegistration }>) => {
      state.canUpdate = action.payload.updateable;
      state.serviceworker = action.payload.serviceworker;
      console.log('Redux Store is notified about update');
    },
    changeLayout: (state, action: PayloadAction<NavLayoutTypes>) => {
      state.navLayout = action.payload;
      localStorage.setItem('navLayout', action.payload);
    },
  },
});

// actions
export const {
  canUpdate,
  changeLayout,
} = appSlice.actions;

// selectors
const selectUpdate = (state: RootState) => state.app.canUpdate;
const selectWorker = (state: RootState) => state.app.serviceworker;
const selectLayout = (state: RootState) => state.app.navLayout;

// action creators

// exporting selectors
export const appSelectors = {
  selectUpdate,
  selectWorker,
  selectLayout,
};

// exporting action creators
// export const appActions = {
//   loadUserInfo,
// };

// exporting reducer
export default appSlice.reducer;
