import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import 'typeface-roboto';
import { CssBaseline } from '@material-ui/core';
import { Auth0Provider } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import appSettings from 'app/appSettings';
import { canUpdate } from 'features/app/appSlice';
import Notifier from 'features/notifications/Notifier';
import SnackbarCloseButton from 'features/notifications/SnackbarCloseButton';
import EncryptedAuth0TokenCache from 'utils/EncryptedAuth0TokenCache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import theme from './theme';
import App from './App';
import store from './app/store';

ReactDOM.render(
  <Auth0Provider
    domain={appSettings.auth0.domain}
    clientId={appSettings.auth0.clientId}
    audience={appSettings.auth0.audience}
    scope={appSettings.auth0.scope}
    cache={EncryptedAuth0TokenCache}
    redirectUri={window.location.origin}
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left', // So it does not overshadow the stop button at the bottom with the thumb
          }}
          action={(id) => <SnackbarCloseButton id={id} />}
        >
          <CssBaseline />
          <Notifier />
          <App />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (reg: ServiceWorkerRegistration) => {
    store.dispatch(canUpdate({ updateable: true, serviceworker: reg }));
  },
});
