/* eslint-disable import/no-cycle */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { navigatorApi } from 'features/navigator/api';
import notificationReducer from 'features/notifications/notificationsSlice';
import userInfoReducer from 'features/user/userInfoSlice';
import appReducer from 'features/app/appSlice';
import { smsApi } from '../services/smsApi';
import { cabinetApi } from '../services/cabinetApi';
import { fleetApi } from '../services/fleetApi';

const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    userInfo: userInfoReducer,
    app: appReducer,
    [navigatorApi.reducerPath]: navigatorApi.reducer,
    [cabinetApi.reducerPath]: cabinetApi.reducer,
    [fleetApi.reducerPath]: fleetApi.reducer,
    [smsApi.reducerPath]: smsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    navigatorApi.middleware,
    cabinetApi.middleware,
    fleetApi.middleware,
    smsApi.middleware,
  ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType, // eslint-disable-line @typescript-eslint/indent
  RootState, // eslint-disable-line @typescript-eslint/indent
  unknown, // eslint-disable-line @typescript-eslint/indent
  Action<string> // eslint-disable-line @typescript-eslint/indent
>;

export default store;
