import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const colors = {
  rallyGreen: '#7dff64', // '#1EB980','
  rallyGreenDark: '#045D56',
  rallyPurple: '#B15DFF',
  rallyBlue: '#72DEFF',
  rallyOrange: '#FF6859',
  rallyYellow: '#FFCF44',
  backgroundPaper: '#282a22',
  colorPaper: '#e7e7e6',
  orange: 'orange',
  red: 'red',
  green: 'green',
  white: 'white',
  black: 'black',
  gray: 'grey',
  navyBlue: '#0043FF',
  lightgreen: '#98fb98',
};

export const colorV2 = {
  // For dark
  background: '#212720',
  primary: '#4f5759',
  primaryLight: '#e0e3e2',
  lightText: '#292921',
  textColor: '#e7e7e6',
  stingrayGreen: '#abd688',
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: colorV2.background,
      paper: colorV2.primary,
    },
    text: {
      primary: colorV2.textColor,
    },
    primary: {
      main: colorV2.stingrayGreen,
      contrastText: colorV2.lightText,
    },
    secondary: {
      main: colors.rallyYellow,
    },
    common: {
      black: colorV2.background,
      white: colorV2.textColor,
    },
    error: {
      main: red.A400,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    allVariants: {
      color: colorV2.textColor,
    },
  },
});
theme.typography.caption = {
  [theme.breakpoints.down('sm')]: {
    fontSize: '.7rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
  },
};
theme.typography.body1 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
};

export default theme;
