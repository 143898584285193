import { Cacheable, ICache } from '@auth0/auth0-react';
import { encrypt, decrypt, hash } from './cryptoUtils';

const hashKey = (key: string) => hash(`AUTH0_CACHE_${key}`);

function set<T = Cacheable>(key: string, entry: T) {
  const hashedKey = hashKey(key);
  localStorage.setItem(hashedKey, encrypt(JSON.stringify(entry)));
}

function get<T = Cacheable>(key: string) {
  const hashedKey = hashKey(key);
  const item = localStorage.getItem(hashedKey);
  if (!item) {
    return null;
  }

  return JSON.parse(decrypt(item)) as T;
}

function remove(key: string) {
  const hashedKey = hashKey(key);
  localStorage.removeItem(hashedKey);
}

const EncryptedAuth0TokenCache: ICache = {
  get, set, remove,
};

export default EncryptedAuth0TokenCache;
