import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import appSettings from 'app/appSettings';
import { RootState } from 'app/store';
import qs from 'qs';
import { FleetFlat, FleetFlatQueryParams } from '../features/fleet/types';

export const fleetApi = createApi({
  reducerPath: 'fleetApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.apis.equipmentPlacementApi.url,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).userInfo;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFleetFlat: builder.query<FleetFlat, FleetFlatQueryParams>({
      query: (fleetflatQuery: FleetFlatQueryParams) => `fleet/flat?${qs.stringify(fleetflatQuery)}`,
    }),
  }),
});

export const {
  useGetFleetFlatQuery,
} = fleetApi;
