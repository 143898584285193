import { encrypt as aesEncrypt, decrypt as aesDecrypt } from 'crypto-js/aes';
import SHA1 from 'crypto-js/sha1';
import { enc } from 'crypto-js';

const passphrase = 'EQmKnVDGqTbdmja';

export const hash = (content: string) => SHA1(content).toString();

export const encrypt = (content: string) => {
  const result = aesEncrypt(content, passphrase).toString();
  return result;
};

export const decrypt = (content: any) => {
  const result = aesDecrypt(content, passphrase);
  return result.toString(enc.Utf8);
};
