import { makeStyles } from '@material-ui/core';
import React from 'react';
import StingrayLogo from '../../assets/stingray_logo_large.png';

type Props = {
  children: React.ReactNode,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  image: {
    maxWidth: '20rem',
    width: '90%',
  },
}));

const SplashScreen = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={StingrayLogo} alt="stingray logo" />
      {children}
    </div>
  );
};

export default React.memo(SplashScreen);
