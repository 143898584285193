import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import appSettings from 'app/appSettings';
import { RootState } from 'app/store';
import qs from 'qs';
import {
  GsmSetupStatusQueryParams,
  MigrationStatusResponseModel,
} from './types';

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.apis.smsService.url,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).userInfo;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMigrationStatus: builder.query<MigrationStatusResponseModel, GsmSetupStatusQueryParams>({
      query: (queryParams) => `migration/status?${qs.stringify(queryParams)}`,
    }),
  }),
});

export const {
  useGetMigrationStatusQuery,
} = smsApi;
