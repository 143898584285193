import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import appSettings from 'app/appSettings';
import { RootState } from 'app/store';
import { Command } from 'features/navigator/navigatorTypes';
import qs from 'qs';
import {
  CommandInput,
  GsmCommandInput, GsmCommandOutput, SensorData,
} from '../features/cabinet/types';
import { ListLatestSmsQueryParmas, PaginationResponseModel, PowerStatusResponseModel } from './types';

export const cabinetApi = createApi({
  reducerPath: 'cabinetApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.apis.cabinet.url,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).userInfo;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Gsms', 'Gsm'],
  endpoints: (builder) => ({
    postGsmCommand: builder.mutation<GsmCommandOutput, { commandIn: GsmCommandInput }>({
      query: (query) => ({
        url: `cabinets/${query.commandIn.cabinet}/gsm`,
        method: 'POST',
        body: query.commandIn,
      }),
      invalidatesTags: ['Gsms'],
    }),
    getSms: builder.query<PaginationResponseModel, { queryParams: ListLatestSmsQueryParmas | undefined }>({
      query: ({ queryParams }) => `cabinets/latest-sms?${qs.stringify(queryParams)}`,
    }),
    getLastPowerStatus: builder.query<PowerStatusResponseModel, { cabinetId: string | null }>({
      query: ({ cabinetId }) => `cabinet/${cabinetId}/power-status`,
    }),
    getCabinetSensors: builder.query<SensorData, string | null>({
      query: (cabinetId) => `cabinets/${cabinetId}/sensors`,
    }),
    createCommand: builder.mutation<Command, { cabinetId: string | null, commandIn: CommandInput | null }>({
      query: (query) => ({
        url: `equipment/${query.cabinetId}/commands`,
        method: 'POST',
        body: query.commandIn,
      }),
    }),
  }),
});

export const {
  useGetSmsQuery,
  useGetLastPowerStatusQuery,
  usePostGsmCommandMutation,
  useGetCabinetSensorsQuery,
  useCreateCommandMutation,
} = cabinetApi;
