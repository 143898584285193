import AppSettingsTemplate from './appSettings.template';

const productionAppSettings: AppSettingsTemplate = {
  auth0: {
    domain: 'stingray.eu.auth0.com',
    clientId: 'yojseljcFrmnjulhIQolRZpbsioQd6Cg',
    audience: 'https://stingrayapis.skywater.io/',
    scope: `Read:EquipmentPlacementFleet
             ReadWrite:NavigatorCommands
             Read:NavigatorPositions
             Read:NodesVideoStreams
             Read:NodesDataStreams
             Read:NavigatorCommands
             ReadWrite:CabinetControlCommands
             ReadWrite:CabinetControlSms
             ReadWrite:CabinetControlGsmSettings
             ReadWrite:NavigatorSchedules
             ReadWrite:NavigatorThrusters`,
  },
  apis: {
    authService: {
      url: 'https://clientapi.lc.stingray.no/api/authservice',
    },
    stingrayApps: {
      url: 'https://hq.skywater.io/webapp-registry/api/v1.0',
    },
    equipmentPlacementApi: {
      url: 'https://equipmentplacement-api.skywater.io/api/v1/',
    },
    navigatorApi: {
      url: 'https://clientapi.lc.stingray.no/navigator/api/navigator/v4/',
    },
    imageStreamServer: {
      url: 'https://imagestream.skywater.io/hubs/imageStream',
    },
    equipmentStatusServer: {
      url: 'https://equipmentstatus-api.skywater.io/api/v1/Nodes/availability/online-units?',
    },
    cabinet: {
      url: 'https://cabinet-backend.skywater.io/api/cabinet/v4.1/',
    },
    nodeStatusService: {
      url: 'https://clientapi.lc.stingray.no/nodestatusservice/api/',
    },
    smsService: {
      url: 'https://clientapi.lc.stingray.no/smsservice/api/',
    },
  },
};

export default productionAppSettings;
