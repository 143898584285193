export enum AccessRight {
  Read = 'Read',
  ReadWrite = 'ReadWrite',
}

export enum Resource {
  EquipmentPlacementFleet = 'EquipmentPlacementFleet',
  NavigatorCommands = 'NavigatorCommands',
  NodesVideoStreams = 'NodesVideoStreams',
  NodesDataStreams = 'NodesDataStreams',
}

export type Permission = {
  resource: Resource,
  accessRight: AccessRight,
  localityType: string,
};

export type UserInfo = {
  userExternalId: string,
  eMail: string,
  groupIds: Array<string>,
  permissions: Array<Permission>,
};
