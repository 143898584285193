import AppSettingsTemplate from './appSettings.template';
import developmentAppSettings from './appSettings.development';
import stagingAppSettings from './appSettings.staging';
import productionAppSettings from './appSettings.production';

enum EnvironmentEnum {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

const appSettingsByEnvironment: { [k in EnvironmentEnum]: AppSettingsTemplate } = {
  [EnvironmentEnum.development]: developmentAppSettings,
  [EnvironmentEnum.staging]: stagingAppSettings,
  [EnvironmentEnum.production]: productionAppSettings,
};

declare global {
  interface Window { REACT_APP_ENV?: EnvironmentEnum }
}
const environment = (window.REACT_APP_ENV || process.env.REACT_APP_ENV)?.toLowerCase() as EnvironmentEnum;
const appSettings = appSettingsByEnvironment[environment];

export default appSettings;
