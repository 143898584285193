import React, { Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SignIn from 'features/user/SignIn';
import SplashScreen from 'features/layout/SplashScreen';

const AppAuthorizedContent = React.lazy(() => import('features/layout/AppAuthorizedContent'));

const App = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      {isAuthenticated
        ? (
          <Suspense fallback={<SplashScreen>Loading</SplashScreen>}>
            <AppAuthorizedContent />
          </Suspense>
        )
        : <SplashScreen><SignIn /></SplashScreen>}
    </>
  );
};

export default App;
