/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { getUserInfo } from './api';
import { AccessRight, Permission, Resource } from './types';

type UserInfoState = {
  permissions: Array<Permission>,
  isLoaded: boolean,
  isFetching: boolean,
  error: string | null,
  token: string | null,
  tokenExpire: null | number
};

const initialState: UserInfoState = {
  permissions: [],
  isLoaded: false,
  isFetching: false,
  error: null,
  token: null,
  tokenExpire: null,
};

export const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<Permission>>) => {
      state.isFetching = false;
      state.error = null;
      state.isLoaded = true;
      state.permissions = action.payload;
    },
    setToken: (state, action: PayloadAction<{ token: string; expire?: number }>) => {
      state.token = action.payload.token;
      if (action.payload.expire) {
        state.tokenExpire = action.payload.expire;
      }
    },
  },
});

// actions
const {
  fetchStart, fetchFailure, fetchSuccess, setToken,
} = userInfoSlice.actions;

// selectors
const selectPermissions = (state: RootState) => state.userInfo.permissions;
const selectIsFetching = (state: RootState) => state.userInfo.isFetching;
const selectIsLoaded = (state: RootState) => state.userInfo.isLoaded;
const selectError = (state: RootState) => state.userInfo.error;
const selectTokenInfo = (state: RootState) => ({ token: state.userInfo.token, expire: state.userInfo.tokenExpire });

const selectHasReadAccess = (resource: Resource) => (state: RootState) => {
  const permission = state.userInfo.permissions.find((p) => p.resource === resource);
  return !!permission;
};

const selectHasWriteAccess = (resource: Resource) => (state: RootState) => {
  const permission = state.userInfo.permissions.find((p) => p.resource === resource);
  if (permission && permission.accessRight === AccessRight.ReadWrite) {
    return true;
  }
  return false;
};

// action creators
const loadUserInfo = (accessToken: string): AppThunk => async (dispatch, getState) => {
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const userInfo = await getUserInfo(accessToken);
    dispatch(fetchSuccess(userInfo.permissions));
  } catch (err: any) {
    // trying to get error message from exception.
    const text = typeof err.message === 'string' ? err.message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load user info',
      text,
    }));
  }
};

// exporting selectors
export const userInfoSelectors = {
  selectPermissions,
  selectIsFetching,
  selectIsLoaded,
  selectError,
  selectHasReadAccess,
  selectHasWriteAccess,
  selectTokenInfo,
};

// exporting action creators
export const userInfoActions = {
  loadUserInfo,
  setToken,
};

// exporting reducer
export default userInfoSlice.reducer;
