import appSettings from 'app/appSettings';
import { get } from 'core/communication';
import { UserInfo } from './types';

const BASE_URL = appSettings.apis.authService.url;

// eslint-disable-next-line import/prefer-default-export
export async function getUserInfo(accessToken: string): Promise<UserInfo> {
  const url = `${BASE_URL}/users/userinfo/permissions`;
  const result = await get<UserInfo>(url, accessToken);
  return result;
}
