import React, { useEffect } from 'react';
import {
  makeStyles, Button, LinearProgress, Typography, Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAuth0 } from '@auth0/auth0-react';
import useNavigatorOnLine from 'customHooks/useNavigatorOnLine';
import UpdateApp from 'features/app/UpdateApp';

const useStyles = makeStyles(() => ({
  loadingIndicator: {
    width: '20rem',
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const onlineStatus = useNavigatorOnLine();
  const {
    isLoading, loginWithPopup, getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => { // Check if token already exist
    getAccessTokenSilently({ timeoutInSeconds: 10 }).catch(() => console.log('Needs to sign in'));
  }, []);

  return (
    <>
      {
        isLoading
          ? <LinearProgress className={classes.loadingIndicator} />
          : (
            <>
              <Box m={2}>
                <Typography variant="h6">Navigator Mobile</Typography>
              </Box>
              <Button variant="contained" color="primary" onClick={loginWithPopup}>Sign In</Button>
            </>
          )
        }
      {!onlineStatus && (<Alert severity="warning">Seems like your device is offline!</Alert>)}
      <UpdateApp />
    </>
  );
};

export default SignIn;
