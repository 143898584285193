import AppSettingsTemplate from './appSettings.template';

const developmentAppSettings: AppSettingsTemplate = {
  auth0: {
    domain: 'stingray-staging.eu.auth0.com',
    clientId: 'nnB6OfRmBtqbcxZwCpMnLKgOzrCSmrzB',
    audience: 'https://stingrayapis.skywater.io/',
    scope: `Read:EquipmentPlacementFleet
             ReadWrite:NavigatorCommands
             Read:NavigatorPositions
             Read:NodesVideoStreams
             Read:NodesDataStreams
             Read:NavigatorCommands
             ReadWrite:CabinetControlCommands
             ReadWrite:CabinetControlSms
             ReadWrite:CabinetControlGsmSettings
             ReadWrite:NavigatorSchedules
             ReadWrite:NavigatorThrusters`,
  },
  apis: {
    authService: {
      url: 'https://lcclienttest.skywater.io/api/authservice',
    },
    stingrayApps: {
      url: 'https://hq.skywater.io/webapp-registry/api/v1.0',
    },
    equipmentPlacementApi: {
      url: 'https://staging.equipmentplacement-api.skywater.io/api/v1/',
    },
    navigatorApi: {
      url: 'https://lcclienttest.skywater.io/navigator/api/navigator/v4/',
    },
    imageStreamServer: {
      url: 'https://staging.imagestream.skywater.io/hubs/imagestream',
    },
    equipmentStatusServer: {
      // eslint-disable-next-line max-len
      url: 'https://fleetmanagement-equipmentstatusserver-staging.nsz-ba-k8s-cluster.stingray.internal/api/v1/Nodes/availability/online-units?',
    },
    cabinet: {
      url: 'https://staging-cabinet-backend.skywater.io/api/cabinet/v4.1/',
    },
    nodeStatusService: {
      url: 'https://lcclienttest.skywater.io/nodestatusservice/api/',
    },
    smsService: {
      url: 'https://lcclienttest.skywater.io/smsservice/api/',
    },
  },
};

export default developmentAppSettings;
