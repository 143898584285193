import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import appSettings from 'app/appSettings';
import { RootState } from 'app/store';
import { PaginatedResponse } from 'core/types';
import qs from 'qs';
import {
  Command, CommandInput, CommandsQueryParams, FavoritePosition, FavoritePositionQueryParams, FavoritePositionType, NodeSensors,
} from './navigatorTypes';

export const navigatorApi = createApi({
  reducerPath: 'navigatorCommands',
  tagTypes: ['Commands', 'Command', 'LocationCommands', 'FavoritePosition'],
  baseQuery: fetchBaseQuery({
    baseUrl: appSettings.apis.navigatorApi.url,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).userInfo;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createCommand: builder.mutation<Command, { cableId: number, commandIn: CommandInput, endpoint?: 'BU' | 'SU' }>({
      query: (query) => ({
        url: !query.endpoint
          ? `cables/${query.cableId}/commands`
          : `cables/${query.cableId}/commands?endpoint=${query.endpoint}`,
        method: 'POST',
        body: query.commandIn,
      }),
      invalidatesTags: ['Commands', 'LocationCommands'],
    }),
    getCommands: builder.query<PaginatedResponse<Command>, CommandsQueryParams>({
      query: (query) => `commands?${qs.stringify(query, { allowDots: true })}`,
      providesTags: ['Commands'],
    }),
    getCommand: builder.query<Command, string>({
      query: (id) => `commands/${id}`,
      providesTags: (command) => (command ? [{ type: 'Command', id: command.id }] : []),
    }),
    createFavoritePositionCommandByType: builder.mutation<Command, { cableId: number, favoriteType: FavoritePositionType }>({
      query: (query) => ({
        url: `cables/${query.cableId}/commands/favorites/types/${query.favoriteType}`,
        method: 'POST',
      }),
      invalidatesTags: ['Commands', 'LocationCommands'],
    }),
    abortCommand: builder.mutation<Command, Command>({
      query: (command) => ({
        url: `cables/${command.destination.cableId}/commands/${command.id}/abort`,
        method: 'PATCH',
      }),
      invalidatesTags: (command) => (command ? [{ type: 'Command', id: command.id }] : []),
    }),
    getNodeSensors: builder.query<NodeSensors, string>({
      query: (nodeId) => `nodes/${nodeId}/sensors`,
    }),
    getFavoritePositions: builder.query<PaginatedResponse<FavoritePosition>, FavoritePositionQueryParams>({
      query: (query) => `favorites?${qs.stringify(query, { allowDots: true })}`,
      providesTags: ['FavoritePosition'],
      async onQueryStarted(_, { /* dispatch, */ queryFulfilled }) {
        // try {
        await queryFulfilled;
        // } catch (error) {
        //   errorHandler(error, dispatch, 'Failed to load favorite positions!', 'error');
        // }
      },
    }),
  }),
});

export const {
  useCreateCommandMutation,
  useGetCommandsQuery,
  useGetCommandQuery,
  useCreateFavoritePositionCommandByTypeMutation,
  useAbortCommandMutation,
  useGetNodeSensorsQuery,
  useGetFavoritePositionsQuery,
} = navigatorApi;
